import { render, staticRenderFns } from "./Diseases.vue?vue&type=template&id=6730f79d&scoped=true"
import script from "./Diseases.vue?vue&type=script&lang=ts"
export * from "./Diseases.vue?vue&type=script&lang=ts"
import style0 from "./Diseases.vue?vue&type=style&index=0&id=6730f79d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6730f79d",
  null
  
)

export default component.exports