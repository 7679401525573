
import { Component, Vue } from 'vue-property-decorator'
import ImportData from '@/components/importTemplateData/Index.vue'
import TypeDialog from './components/TypeDialog.vue'
import { TypeInfo } from '../../types/common'
import { Info } from '../../types/diseases'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'DiseasesList',
  components: { ImportData, TypeDialog }
})
@KeepAlive
export default class extends Vue {
  private typeList: TypeInfo[] = []
  private typeChildList: TypeInfo[] = []
  private searchInfo = {
    diseasesName: '',
    diseasesType: '',
    diseasesChildType: ''
  }

  private loading = false
  private list: Array<Info> = []

  private total = 0
  private page = 1
  private size = 10
  private isShowImport = false
  private typeShow = false
  created () {
    this.getTypeList()
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get(this.$apis.diseases.diseasesPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    }).then((res) => {
      this.list = res.list || []
      this.total = res.total || 0
      this.loading = false
    }).finally(() => {
      this.loading = false
    })
  }

  // 获取病害类型列表
  getTypeList () {
    this.$axios.get<{total: number; list: TypeInfo[]}>(this.$apis.common.thinktankTypeList, {
      dicType: 'diseasesType'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  // 获取病害类型对应的病原类型
  getChildTypeList (pdicCode: string) {
    this.$axios.get<{total: number; list: TypeInfo[]}>(this.$apis.common.thinktankTypeList, {
      dicType: 'diseasesChildType',
      pdicCode: pdicCode
    }).then((res) => {
      this.typeChildList = res.list || []
    })
  }

  diseasesTypeChange (val: string) {
    this.searchInfo.diseasesChildType = ''
    this.typeChildList = []
    if (val) {
      this.getChildTypeList(val)
    }
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'diseasesDetail',
      params: { id }
    })
  }

  onAdd () {
    this.$router.push({
      name: 'diseasesAdd'
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'diseasesUpdate',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      this.$axios.post(this.$apis.diseases.deleteDiseases, {
        diseasesId: id
      }).then(() => {
        this.$message.success('删除成功')
        this.getData()
      })
    })
  }
}
