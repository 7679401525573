
import { Component, Vue, Prop } from 'vue-property-decorator'
import { TypeInfo } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component
export default class InsectTypeDialog extends Vue {
  @Prop() readonly typeShow!: boolean
  private insectTypeList: TypeInfo[] = []
  private props = {
    value: 'dicId',
    label: 'dicValue',
    children: 'childNode'
  }

  private showChildDlg = false
  private isEdit = false
  private isChild = false
  private submitShow = false
  private formInfo: TypeInfo = {
    dicValue: '',
    pdicCode: '',
    dicType: 'diseasesType'
  }

  private rules = {
    dicValue: [{ required: true, message: '请输入病害名称', trigger: ['blur'] }]
  }

  private childRules = {
    dicValue: [{ required: true, message: '请输入病原名称', trigger: ['blur'] }]
  }

  created () {
    this.getInsectTypeList()
  }

  closeDialog () {
    this.$emit('update:typeShow', false)
    this.$emit('closeDialog')
  }

  getInsectTypeList () {
    this.$axios
      .get(
        this.$apis.common.selectThinktankDicList,
        {
          dicType: 'diseasesType'
        }
      )
      .then((res) => {
        this.insectTypeList = res
      })
  }

  //  新增一级病害
  onParentAdd () {
    this.isEdit = false
    this.isChild = false
    this.formInfo = {
      dicValue: '',
      pdicCode: '',
      dicType: 'diseasesType'
    }
    this.showChildDlg = true
  }

  // 新增
  submitForm () {
    ;(this.$refs.formInfo as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const info = this.formInfo.dicId
          ? {
            dicId: this.formInfo.dicId,
            ...this.formInfo
          }
          : this.formInfo

        const url = this.formInfo.dicId
          ? this.$apis.common.updateThinktankDic
          : this.$apis.common.insertThinktankDic

        this.$axios
          .post(url, info)
          .then(() => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            this.showChildDlg = false
            this.getInsectTypeList()
          })
          .finally(() => {
            this.submitShow = false
          })
      }
    })
  }

  // 二级新增
  onChildAdd (node: { data: TypeInfo }) {
    this.isEdit = false
    this.isChild = true
    this.formInfo = {
      dicType: 'diseasesChildType',
      pdicCode: node.data.dicCode,
      dicValue: ''
    }
    this.showChildDlg = true
  }

  // 编辑
  onEdit (node: { data: TypeInfo; level: number }) {
    this.isEdit = true
    this.isChild = node.level !== 1
    this.$axios.get(this.$apis.common.selectThinktankByDicId, { dicId: node.data.dicId }).then(res => {
      this.formInfo = res
      this.showChildDlg = true
    })
  }

  // 删除
  onDelete (node: { data: TypeInfo }) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.common.deleteThinktankDic, {
          dicId: node.data.dicId
        }).then(() => {
          this.$message.success('删除成功')
          this.getInsectTypeList()
        })
      })
  }
}
